
import BotSearch from '@/components/BotSearch.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import NavigationList from '@/components/NavigationList.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import UserCircle from '@/components/UserCircle.vue';
import { RouteNames } from '@/constants';
import { UINavigationItem } from '@/types';
import { currentYear } from '@/utils/time';
import { Menu } from '@gsk-tech/gsk-menu';
import { TopAppBar } from '@gsk-tech/gsk-top-app-bar/gsk-top-app-bar';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { UserModule } from '../store/modules/user.module';
import { BaseUser } from '../types/users.types';

type GskMenu = Menu;

@Component({
  components: {
    GButton,
    TopNavigation,
    NavigationList,
    GTextfield,
    BotSearch,
    UserCircle
  },
})
class DefaultLayout extends Vue {
  private navOpen = false;

  @Watch('$route')
  onRouteChange() {
    this.navOpen = false;
  }

  get currentYear() {
    return currentYear();
  }

  get navLinks(): UINavigationItem[] {
    const links: UINavigationItem[] = [
      {
        text: 'Scheduled Reporting',
        key: 'scheduled-reporting',
        exact: true,
        route: {
          name: RouteNames.RpaOpsScheduledReporting,
          query: {},
        },
      },
      {
        text: 'Unassigned Bots',
        key: 'unassigned-bots',
        exact: true,
        route: {
          name: RouteNames.RpaOpsUnassignedBots,
          query: {},
        },
      },
      {
        text: 'Dashboard',
        key: 'om27',
        exact: true,
        route: {
          name: RouteNames.Om27,
          query: this.$route.name === RouteNames.Om27 ? this.$route.query : {},
        },
      },
    ];

    return links;
  }

  get drawerEventHandlers() {
    return {
      opened: () => {
        this.navOpen = true;
      },
      closed: () => {
        this.navOpen = false;
      },
    };
  }

  mounted() {
    const bar = this.$refs.appBar as TopAppBar;
    const main = this.$refs.main as HTMLElement;
    bar.scrollTarget = main;
  }

  showMenu(): void {
    const menu = this.$refs.menu as GskMenu;
    const anchor = this.$refs.menuAnchor as Vue;
    menu.setAnchorElement(anchor.$el);
    menu.setAnchorCorner(menu.Corner.BOTTOM_LEFT);
    menu.open = !menu.open;
  }

  routeToDashboard() {
    if (this.$route.name !== RouteNames.Om27) {
      this.$router.push('/')
    }
  }

  get user(): BaseUser {
    return UserModule.user;
  }

}

export default DefaultLayout;
